





































import {Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import querystring from "query-string";

const items = [
  {
    color: "#720000",
    logo: "/assets/img/gift/pandemomo_logo.jpg",
    image: "/assets/img/gift/00209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#720000",
    logo: "/assets/img/gift/pandemomo_logo.jpg",
    image: "/assets/img/gift/01209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#FFFFFF",
    title: "ドラッグストア",
    image: "/assets/img/gift/02209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "プロテイン",
  },
  {
    color: "#FFFFFF",
    title: "ドーナツショップ",
    image: "/assets/img/gift/03209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#FFFFFF",
    title: "コーヒーショップ",
    image: "/assets/img/gift/04209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#FFFFFF",
    title: "アイス屋さん",
    image: "/assets/img/gift/05209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#FFFFFF",
    title: "コンビニエンスストア",
    image: "/assets/img/gift/06209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#FFFFFF",
    title: "ファーストフード",
    image: "/assets/img/gift/07209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#FFFFFF",
    title: "コンビニエンスストア",
    image: "/assets/img/gift/08209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#FFFFFF",
    title: "コンビニエンスストア",
    image: "/assets/img/gift/09209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#FFFFFF",
    title: "ファーストフード",
    image: "/assets/img/gift/10209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "Eギフトチケット",
  },
  {
    color: "#FFFFFF",
    title: "コンビニエンスストア",
    image: "/assets/img/gift/11209841-de77-44e3-a3f5-d518a969a172.png",
    itemName: "板ガム",
  },
]

@Component
export default class App extends Vue {
  public show = false;
  public item: { color: string; logo?: string, title?: string, itemName: string, image: string } | null = null;
  
  public mounted(): void {
    const qs = querystring.parse(window.location.search);
    const itemIndex = parseInt(qs["item"] as string, 10);
    this.item = items[itemIndex] ?? null;
    setTimeout(() => {
      this.show = true;
    }, 400)
  }
}
