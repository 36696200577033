import Vue, {CreateElement, VNode} from "vue";

import App from "./components/App.vue";

import vuetify from "./plugins/vuetify";

new Vue({
  render: (h: CreateElement): VNode => h(App),
  vuetify,
}).$mount("#app");
